import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/hegel/hegel/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "installation"
    }}>{`Installation`}</h1>
    <hr></hr>
    <p><strong parentName="p">{`Step 1`}</strong>{`: check your `}<a parentName="p" {...{
        "href": "https://nodejs.org/en/"
      }}>{`Node.js`}</a>{` version:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ node -v
v12.0.0
`}</code></pre>
    <p>{`Hegel was developed for current LTS version of `}<a parentName="p" {...{
        "href": "https://nodejs.org/en/"
      }}>{`Node.js (12.16.1)`}</a>{`. So, you need to have at least 12 version.`}</p>
    <p>{`If you have less than 12 version of `}<a parentName="p" {...{
        "href": "https://nodejs.org/en/"
      }}>{`Node.js`}</a>{` you may change it to 12 or latest by `}<a parentName="p" {...{
        "href": "https://github.com/nvm-sh/nvm"
      }}><inlineCode parentName="a">{`nvm`}</inlineCode></a>{`.`}</p>
    <p><strong parentName="p">{`Step 2`}</strong>{`: install `}<inlineCode parentName="p">{`@hegel/cli`}</inlineCode>{` with npm globally or locally:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`# globally
$ npm install -g @hegel/cli

# locally
$ npm install -D @hegel/cli
`}</code></pre>
    <p><strong parentName="p">{`Finally`}</strong>{`. You already can use it into your JavaScript project:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`# globally
$ hegel
No errors!

# locally
$ npx hegel
No errors!
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`Hegel has a zero-configuration, but if you want to change settings see `}<a parentName="p" {...{
          "href": "https://hegel.js.org/docs/configuration"
        }}>{`Configuration Section`}</a>{`.`}</p>
    </blockquote>
    <h2 {...{
      "id": "setup-project"
    }}>{`Setup Project`}</h2>
    <p>{`Also you need to setup a compiler which will strip away Hegel types. The same as Flow, you can choose between `}<a parentName="p" {...{
        "href": "https://babeljs.io/"
      }}>{`Babel`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/flow-remove-types"
      }}>{`flow-remove-types`}</a>{`.`}</p>
    <h3 {...{
      "id": "babel"
    }}>{`Babel`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ npm i -D @babel/core @babel/cli @babel/preset-flow
`}</code></pre>
    <p>{`After that you need to create a .babelrc file at the root of your project with next content:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "presets": [["@babel/preset-flow", { "all": true }]]
}
`}</code></pre>
    <p>{`Now you can run it manualy:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ npx babel directory_with_your_project_files/ -- -d compilation_destination_directory
`}</code></pre>
    <p>{`Or you can add script inside your `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` `}<inlineCode parentName="p">{`scripts`}</inlineCode>{` section:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "scripts": {
    "build": "babel directory_with_your_project_files/ -d compilation_destination_directory/"
  }
}
`}</code></pre>
    <h3 {...{
      "id": "flow-remove-types"
    }}>{`Flow Remove Types`}</h3>
    <p>{`Install `}<inlineCode parentName="p">{`flow-remove-types`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ npm i -D flow-remove-types
`}</code></pre>
    <p>{`And add next script inside your `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` `}<inlineCode parentName="p">{`scripts`}</inlineCode>{` section:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "scripts": {
    "build": "flow-remove-types directory_with_your_project_files/ --out-dir compilation_destination_directory/ --all"
  }
}
`}</code></pre>
    <p>{`And run it by:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ npm run build
`}</code></pre>
    <blockquote>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "https://babeljs.io/setup"
        }}>{`"More about Babel setup"`}</a></p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      